<template>
  <div>
    <div v-if="!param.isApprContent" class="col float-right">
      <q-btn-group outline>
        <!-- 업체요청 반려 -->
        <!-- <c-btn
          v-show="param.vendorFlag&&isVendorRequest"
          label="LBL0001160"
          icon="front_hand" color="red-3"
          @btnClicked="returnDialogOpen" /> -->
        <!-- 위험성평가 삭제 -->
        <c-btn 
          v-show="!deleteDisabled" 
          label="LBL0001161"  
          icon="remove" 
          @btnClicked="removePlan" />
        <c-appr-btn 
          ref="appr-btn"
          :editable="editable"
          :approvalInfo="approvalInfo"
          @beforeApprAction="approvalRiskAssess"
          @requestAfterAction="getList"
        />
        <!-- 평가완료 -->
        <!-- <c-btn 
          v-show="completeFlag"
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="assessPlan"
          mappingType="PUT"
          label="LBL0001162" 
          icon="check"
          @beforeAction="completeAction"
          @btnCallback="completeActionCallback" /> -->
      </q-btn-group>
    </div>
    <c-tab
      ref="riskActionTab"
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
          <!-- :is="scenarioComponent" -->
        <component
          :is="tab.component"
          :key="tab.key"
          :height="tabHeight"
          :process.sync="tab.process"
          :param.sync="param"
          :assessPlan.sync="assessPlan"
          :planUpdateBtnData="param.planUpdateBtnData"
          @assessComplete="assessComplete"
          @research="research"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
    <!-- <q-dialog v-model="returnDialog.show" persistent>
      <q-card style="min-width: 450px">
        <q-form ref="editForm2">
          <c-card title="반려 사유" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable" 
                  :isSubmit="isReturn"
                  :url="returnUrl"
                  :param="returnData"
                  mappingType="PUT"
                  label="반려" 
                  icon="front_hand" color="red-3"
                  @beforeAction="returnAssess"
                  @btnCallback="returnAssessCallback" />
                <c-btn 
                  label='닫기' 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-textarea
                  :editable="editable"
                  :rows="5"
                  label=""
                  name="returnRemark"
                  v-model="returnData.returnRemark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog> -->
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskAction',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
        isApprContent: false,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      assessPlan: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        ramTechniqueCd: null,  // 평가기법 코드_HAZOP/4m/JSA/CHECKLIST
        ramAssessTypeCd: null,  // 평가구분_최초/정기/수시
        ramAssessTypeCdSave: null,  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        deptCd: '', // 평가부서
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        workArea: '',  // 작업지역
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        regDtStr: '',
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        vendorCd: '',
        sopConstructionId: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        chgUserId: '',  // 수정자 ID
        processes: [], // 대상공정 목록
      },
      // returnData: {
      //   ramRiskAssessmentPlanId: '',
      //   ramStepCd: '',
      //   returnRemark: '',
      //   chgUserId: '',
      // },
      // returnDialog: {
      //   show: false,
      // },
      isVendorRequest: false,
      editable: true,
      isComplete: false,
      isApproval: false,
      // isReturn: false,
      listUrl: '',
      // completeUrl: '',
      approvalUrl: '',
      // returnUrl: '',
      deletePlanUrl: '',
      recheckSaveUrl: '',
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    tabHeight() {
      return this.height
    },
    completeFlag() {
      return this.tabItems 
        && this.tabItems.length > 0
        && this.$_.findIndex(this.tabItems, (item) => {
              return item.process.ramProcessAssessStepCd !== 'RPA0000005'
            }) === -1 
        && this.param.ramStepCd === 'RRS0000010'
    },
    // assessPlan() {
    //   return {
    //     ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
    //     ramStepCd: this.param.ramStepCd,
    //     chgUserId: this.$store.getters.user.userId,
    //   }
    // },
    deleteDisabled() {
      return this.param.ramStepCd === 'RRS0000015'
        // 결재중인 경우 비활성화
        || this.assessPlan.approvalStatusCd === 'ASC0000001'
    },
    techCheck() {
      // HAZOP, CHARM만 기능 비활성화
      return false
      // return this.param.ramTechniqueCd === 'RT00000001'
      //   || this.param.ramTechniqueCd === 'RT00000030'
    },
    scenarioComponent() {
      let components = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        components = () => import(`${'@/pages/ram/hazop/action/hazopActionDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS
        components = () => import(`${'@/pages/ram/kras/action/krasScenario.vue'}`)
      } 
      return components;
    },
    drawComponent() {
      let components = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        components = () => import(`${'@/pages/ram/hazop/action/hazopScenario.vue'}`)
      } 
      return components;
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.assessPlan.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.assessPlan.approvalStatusCd, 
        apprEditable: this.completeFlag, // 결재버튼 활성화 기준
        param: this.assessPlan, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000027', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
          ramStepCd: this.param.ramStepCd,
          ramTechniqueCd: this.param.ramTechniqueCd,
          isApprContent: true
        },
        approvalRequestName: this.assessPlan.assessmentName, // 결재요청명 (문서 title)
        approvalConnId: this.assessPlan.ramRiskAssessmentPlanId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
  },
  watch: {
    'param.searchScenario.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.targetProcess.list.url;
      // this.completeUrl = transactionConfig.ram.assessPlan.complete.url
      this.approvalUrl = transactionConfig.ram.assessPlan.complete.url // 태우지 않음
      // this.returnUrl = transactionConfig.ram.assessPlan.return.url
      this.deletePlanUrl = transactionConfig.ram.assessPlan.delete.url
      this.recheckSaveUrl = transactionConfig.ram.assessPlan.recheck.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramTechniqueCd: this.param.ramTechniqueCd,
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      };
      this.$http.request((_result) => {
        this.$_.extend(this.assessPlan, _result.data)
        this.tabItems = [];
        this.$_.forEach(_result.data.processes, item => {
          if (item && item.draws) {
            this.$_.forEach(item.draws, draw => {
              this.$set(draw, 'name', draw.psiDiagramId);
              this.$set(draw, 'icon', 'architecture');
              this.$set(draw, 'label', draw.diagramTitle + ' (' + draw.diagramNo + ')');
              this.$set(draw, 'componentKey', uid());
              this.$set(draw, 'component', this.drawComponent);
              this.$set(draw, 'draw', draw);
            })
            this.tabItems.push({
              key: uid(),
              name: item.processCd, 
              icon: 'work_outline', 
              label: item.processName, 
              process: item, 
              component: this.scenarioComponent
            })
          } else if (item && item.sops) {
            this.$_.forEach(item.sops, sop => {
              this.$set(sop, 'name', sop.ramJsaAssessJobId);
              this.$set(sop, 'icon', 'architecture');
              this.$set(sop, 'label', sop.jobName);
              this.$set(sop, 'componentKey', uid());
              this.$set(sop, 'component', () => import(`${'@/pages/ram/jsa/action/jsaScenario.vue'}`));
              this.$set(sop, 'sop', sop);
            })
            this.tabItems.push({
              key: uid(),
              name: item.processCd, 
              icon: 'work_outline', 
              label: item.processName, 
              process: item, 
              component: this.scenarioComponent
            })
          } else if (item && item.targets) {
            this.$_.forEach(item.targets, target => {
              this.$set(target, 'name', target.ramAssessChecklistTargetId);
              this.$set(target, 'icon', 'architecture');
              this.$set(target, 'label', `(${target.ramCheckItemClassName}) ${target.checkItemName}`);
              this.$set(target, 'componentKey', uid());
              this.$set(target, 'component', () => import(`${'@/pages/ram/checklist/action/checklistScenario.vue'}`));
              this.$set(target, 'target', target);
            })
            this.tabItems.push({
              key: uid(),
              name: item.processCd, 
              icon: 'work_outline', 
              label: item.processName , 
              process: item, 
              component: this.scenarioComponent
            })
          } else {
            this.tabItems.push({
              key: uid(),
              name: item.processCd, 
              icon: 'work_outline', 
              label: item.processName, 
              process: item, 
              component: this.scenarioComponent
            })
          }
          this.isVendorRequest = item.vendorRequestFlag === 'Y'
        })
        this.tab = _result.data.processes[0].processCd
        this.$refs['riskActionTab'].setValue(this.tab)
      },);
    },
    assessComplete(stepCd) {
      this.getList();
      // stepper 이동
      if (stepCd === 'RRS0000015') {
        this.$emit('emitStep', {
          name: 'stepBystep',
          param: stepCd
        })
      }
    },
    research(place) {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: place,
        }
      })
    },
    // completeAction() {
    //   window.getApp.$emit('CONFIRM', {
    //     title: 'LBLCONFIRM',
    //     message: 'MSGCOMPLETE', // 완료하시겠습니까?,
    //     // TODO : 필요시 추가하세요.
    //     type: 'info', // success / info / warning / error
    //     // 확인 callback 함수
    //     confirmCallback: () => {
    //       this.assessPlan.chgUserId = this.$store.getters.user.userId
    //       this.assessPlan.ramStepCd = 'RRS0000015'

    //       this.isComplete = !this.isComplete
    //     },
    //     // 취소 callback 함수
    //     cancelCallback: () => {
    //     },
    //   });
    // },
    // completeActionCallback() {
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    //   // 계획 상세 조회
    //   this.getList();
    //   // stepper 이동
    //   this.$emit('emitStep', {
    //     name: 'stepBystep',
    //     param: this.assessPlan.ramStepCd
    //   })
    // },
    // returnDialogOpen() {
    //   this.returnDialog.show = true;
    // },
    // closeDialog() {
    //   this.returnDialog.show = false;
    //   Object.assign(this.$data.returnDialog, this.$options.data().returnDialog);
    // },
    // returnAssess() {
    //   if (!this.returnData.returnRemark) {
    //     window.getApp.$emit('ALERT', {
    //       title: 'LBLGUIDE', // 안내
    //       message: 'MSG0000394', // 반려사유를 입력하세요.
    //       type: 'warning', // success / info / warning / error
    //     });
    //     return;
    //   }
      
    //   window.getApp.$emit('CONFIRM', {
    //     title: 'LBLCONFIRM',
    //     message: 'MSG0000395', // 업체요청 반려하시겠습니까?
    //     // TODO : 필요시 추가하세요.
    //     type: 'info', // success / info / warning / error
    //     // 확인 callback 함수
    //     confirmCallback: () => {
    //       this.$_.extend(this.returnData, this.assessPlan)

    //       this.isReturn = !this.isReturn
    //     },
    //     // 취소 callback 함수
    //     cancelCallback: () => {
    //     },
    //   });
    // },
    // returnAssessCallback() {
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    //   this.closeDialog();
    //   // 계획 상세 조회
    //   this.getList();
    // },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deletePlanUrl, this.param.ramRiskAssessmentPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.closePopup()
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    closePopup() {
      this.$emit('emitStep', {
        name: 'closePopup'
      })
    },
    approvalRiskAssess() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '결재요청하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$refs['appr-btn'].apprRequestPop();
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
