var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.param.isApprContent
        ? _c(
            "div",
            { staticClass: "col float-right" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.deleteDisabled,
                        expression: "!deleteDisabled",
                      },
                    ],
                    attrs: { label: "LBL0001161", icon: "remove" },
                    on: { btnClicked: _vm.removePlan },
                  }),
                  _c("c-appr-btn", {
                    ref: "appr-btn",
                    attrs: {
                      editable: _vm.editable,
                      approvalInfo: _vm.approvalInfo,
                    },
                    on: {
                      beforeApprAction: _vm.approvalRiskAssess,
                      requestAfterAction: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("c-tab", {
        ref: "riskActionTab",
        attrs: {
          type: "vertical",
          tabItems: _vm.tabItems,
          height: _vm.tabHeight,
        },
        on: {
          "update:height": function ($event) {
            _vm.tabHeight = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: {
                    height: _vm.tabHeight,
                    process: tab.process,
                    param: _vm.param,
                    assessPlan: _vm.assessPlan,
                    planUpdateBtnData: _vm.param.planUpdateBtnData,
                  },
                  on: {
                    "update:process": function ($event) {
                      return _vm.$set(tab, "process", $event)
                    },
                    "update:param": function ($event) {
                      _vm.param = $event
                    },
                    "update:assessPlan": function ($event) {
                      _vm.assessPlan = $event
                    },
                    "update:assess-plan": function ($event) {
                      _vm.assessPlan = $event
                    },
                    assessComplete: _vm.assessComplete,
                    research: _vm.research,
                    closePopup: _vm.closePopup,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }